import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Link } from "gatsby"
import { UL } from '../components/list'

export const AllArticles = () => (
  <StaticQuery
    query={graphql`
    query {
      allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC
         }
        filter: {
          fields: { collection: { eq: "blog" } }
          frontmatter: { private: { eq: false }}
        }
      ) {
        totalCount
        edges {
          node {
            ...PostFields
          }
        }
      }
    }
    `}
    render={data => listPosts(data.allMarkdownRemark.edges)}
  />
)

// data.allMarkdownRemark.edges
const listPosts = (data) => {
  const posts = []

  data.forEach((post, i) => {
    const { slug, releaseDate } = post.node.fields
    const { title } = post.node.frontmatter

    posts.push(
      <li key={i}>
        <Link to={slug}>
        {title}
        </Link>
      </li>
    )
  })

  return <UL>{posts}</UL>
}
