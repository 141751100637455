import * as React from "react"
import { AllArticles }  from '../components/allArticles'
import { P } from '../components/paragraph'
import { Link } from "gatsby"

// markup
const IndexPage = () => {
  return (
    <React.Fragment>
      <P className='spacer-m'>
        <Link to='/about'>I'm Denis</Link> 👋 I help companies design their digital products so that they can grow their business. I like to write about it so you can do it too.
      </P>

     <AllArticles />
    </React.Fragment>
  )
}

export default IndexPage
